<template>
  <v-card :class="darkmode || 'grey lighten-5'" class="pa-4" flat>
    <div class="mb-5 font-weight-bold" style="cursor: pointer; width: 100px">
      <v-btn
        depressed
        @click="
          $router.push({
            name: 'EtestStudent'
          })
        "
      >
        <v-icon>mdi-arrow-left</v-icon>
        E-Test
      </v-btn>
    </div>
    <!-- Header E-Test -->
    <v-row no-gutters>
      <v-card
        class="rounded-lg pa-4"
        :class="!darkmode || 'dark-mode'"
        flat
        width="100%"
      >
        <v-row no-gutters>
          <v-col cols="12" sm="3" class="divider pl-2">
            <div class="font-weight-light">{{ $t("app.subjects") }}</div>
            <div class="font-weight-bold">
              {{ dataTable.header.lesson_name }}
            </div>
          </v-col>
          <v-col cols="12" sm="3" class="divider pl-2">
            <div class="font-weight-light">{{ $t("e_test.title") }}</div>
            <div class="font-weight-bold">
              {{ dataTable.header.title }}
            </div>
          </v-col>
          <v-col cols="4" sm="2" class="divider pl-2">
            <div class="font-weight-light">{{ $t("e_test.information") }}</div>
            <div class="font-weight-bold">
              -
            </div>
          </v-col>
          <v-col cols="4" sm="2" class="divider pl-2">
            <div class="font-weight-light">{{ $t("e_test.total_result") }}</div>
            <div class="font-weight-bold">
              {{ etestHistory.header.score }}
            </div>
          </v-col>
          <v-col cols="4" sm="2" class="divider pl-2">
            <div class="font-weight-light">{{ $t("app.score") }}</div>
            <div class="font-weight-bold">
              -
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-row>

    <Pagination
      :to="etestHistory.body.to"
      :total="etestHistory.body.total"
      :currentPage="pagination.page"
      :nextPage="etestHistory.body.next_page_url"
      :prevPage="etestHistory.body.prev_page_url"
      :handler="paginateLimit"
      id="paginate"
      @next="arrowPage"
      @back="arrowPage"
      @search="handleSearch"
    />
    <!-- Body E-Test -->
    <v-row
      id="question-wrapper"
      v-for="item in etestHistory.body.data"
      :key="item.id"
      no-gutters
      class="my-5"
    >
      <v-col cols="12" sm="1" class="align-start d-flex">
        <v-icon v-if="checkAnswer(item)" class="mr-2" color="primary">
          mdi-checkbox-marked-circle
        </v-icon>
        <v-icon v-else class="mr-2" color="red">
          mdi-close-circle
        </v-icon>
        <span class="font-weight-bold">
          {{
            etestHistory.body.data.indexOf(item) +
              etestHistory.body.from -
              1 +
              1
          }}
        </span>
      </v-col>
      <v-col cols="12" sm="11">
        <div>
          <v-row>
            <v-col>
              <span v-html="item.question" class="pt-2" />
            </v-col>
          </v-row>
        </div>
        <div v-if="item.type === 'esay'">
          <v-row>
            <v-col>
              <span class="font-weight-bold">
                {{ $t("e_test.your_answer") }}
              </span>
              <v-textarea
                solo
                readonly
                :value="answerRender(item.student_answer)"
              ></v-textarea>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row>
            <v-radio-group :value="getChoose(item.student_answer)" readonly row>
              <v-col
                v-for="(itemAnswer, index) in item.pg"
                :key="index"
                cols="12"
                sm="6"
                class="mb-1"
              >
                <v-radio :value="itemAnswer.code">
                  <template v-slot:label>
                    <div class="pt-3 d-flex flex-row">
                      <p class="mr-2">{{ itemAnswer.code }}.</p>
                      <span v-html="itemAnswer.answer" class="answer"></span>
                    </div>
                  </template>
                </v-radio>
              </v-col>
            </v-radio-group>
          </v-row>
        </div>
        <div class="mb-5">
          <v-row>
            <v-col sm="6" v-if="item.type === 'pg'">
              <span class="font-weight-bold">
                {{ $t("e_test.your_answer") }}
              </span>
              <div>
                <span class="d-flex flex-row" v-html="item.student_answer">
                </span>
              </div>
            </v-col>
            <v-col sm="3" v-if="item.type === 'esay'">
              <span class="font-weight-bold">
                {{ $t("e_test.answer_student_file") }}
              </span>
              <div v-if="item.student_answer_file.length">
                <v-btn
                  color="primary"
                  v-on:click="downloadFile(item.student_answer_file)"
                  small
                  >{{ $t("e_test.view_file") }}
                </v-btn>
              </div>
              <div v-else>-</div>
            </v-col>
            <v-col sm="3" v-if="item.type === 'pg'">
              <span class="font-weight-bold">{{ $t("e_test.result") }}</span>
              <div v-if="checkAnswer(item)">
                <v-btn dark x-small tile class="primary">
                  <v-icon small left>
                    mdi-check-circle
                  </v-icon>
                  {{ $t("app.correct") }}
                </v-btn>
              </div>
              <div v-else>
                <v-btn dark x-small tile color="red">
                  <v-icon small left>
                    mdi-close-circle
                  </v-icon>
                  {{ $t("app.incorrect") }}
                </v-btn>
              </div>
            </v-col>
            <v-col sm="2">
              <span class="font-weight-bold">{{ $t("e_test.score") }}</span>
              <div>{{ item.points }}</div>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col sm="12" v-if="item.type === 'pg'">
              <span class="font-weight-bold">
                {{ $t("e_test.clarification") }}
              </span>
              <div class="d-flex flex-row">
                Jawabannya adalah
                <span class="ml-1" v-html="getDescription(item.pg).code"></span
                >,
                <span
                  class="ml-1"
                  v-html="getDescription(item.pg).answer"
                ></span
                >.
              </div>
              <v-divider class="mb-3"></v-divider>
            </v-col>
            <v-col sm="12" v-else>
              <span class="font-weight-bold">
                {{ $t("e_test.clarification") }}
              </span>
              <v-textarea solo readonly :value="answerRender(item.esay.key)">
              </v-textarea>
              <v-divider class="mb-3"></v-divider>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { listStatistic, detailHistory } from "@/api/admin/academic/eTest";
import store from "@/store";

let typingTimer;
const doneTypingInterval = 750;

export default {
  data() {
    return {
      table: {
        limit: 10,
        page: 1,
        search: "",
        loading: false
      },
      dataTable: {
        header: {},
        body: { data: [] }
      },
      etestHistory: {
        header: {},
        body: { data: [] }
      },
      pagination: {
        limit: 10,
        page: 1,
        search: ""
      },
      dataRequest: 0
    };
  },
  components: {
    Pagination: () => import("../../components/Pagination")
  },
  created() {
    this.dataRequest = this.$route.params.id;
  },
  computed: {
    darkmode() {
      return store.getters.g_darkmode;
    }
  },
  watch: {
    dataRequest(value) {
      if (value) {
        this.getDetailTest();
      }
    }
  },
  methods: {
    async getDetailTest() {
      try {
        const response = await listStatistic({
          etest_class: this.dataRequest
        });
        if (response.data.code) {
          this.dataTable = response.data.data;
          this.getDetailHistory();
        } else {
          console.log(false);
        }
        this.$Progress.finish();
      } catch (error) {
        this.$Progress.finish();
        console.log(error);
      }
    },
    async getDetailHistory() {
      this.$Progress.start();
      try {
        const response = await detailHistory({
          etest_class: this.dataRequest,
          ...this.pagination
        });
        if (response.data.code) {
          this.etestHistory = response.data.data;
        } else {
          console.log(false);
        }
      } catch (error) {
        console.log(error);
      }
      this.$Progress.finish();
    },
    paginateLimit(page, limit) {
      this.pagination.page = page;
      this.pagination.limit = limit;
      this.getDetailHistory();
    },
    checkAnswer(item) {
      let answer = true;
      switch (item.result) {
        case true:
          answer = true;
          break;
        case false:
          answer = false;
          break;
        default:
          break;
      }
      return answer;
    },
    handleSearch(value) {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.pagination.search = value;
        this.getDetailHistory();
      }, doneTypingInterval);
    },
    arrowPage(page) {
      this.etestHistory.body.data = [];
      this.pagination.page = page;
      this.getDetailHistory();
    },
    getDescription(item) {
      let answer = item;
      let result = answer.find(data => data.key === true);
      return result;
    },
    getChoose(item) {
      let result = item.charAt(0);
      return result;
    },
    downloadFile(item) {
      let lengthUrl = item.length;
      let result = item.slice(3, lengthUrl);
      window.open(result);
    },
    answerRender(item) {
      let regex = /(<([^>]+)>)/gi;
      let result = item.replace(regex, "");
      return result;
    }
  }
};
</script>

<style lang="scss">
#paginate {
  position: sticky;
  top: 63px;
  z-index: 2;
}
#question-wrapper {
  width: 100%;
  img {
    width: 25%;
    object-fit: contain;
    border-radius: 15px;
  }
}
</style>
